<template>
  <div>
    <el-row type="flex" justify="space-around" class="content-wrap">
      <el-col>
        <el-row>
          <h1 class="text">
            字符串去重
            <small>remove duplicate string</small>
          </h1>
        </el-row>
        <h2>源字符串</h2>
        <el-row class="text">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入内容"
            v-model="str"
          >
          </el-input>
        </el-row>
        <el-row type="flex" justify="space-between" align="middle">
          <el-col :span="12"><h2>生成结果</h2></el-col>
          <el-col style="width: fit-content">
            <el-button
              type="primary"
              plain
              v-clipboard:copy="resultStr"
              v-clipboard:success="onCopy"
              >复制</el-button
            >
          </el-col>
        </el-row>
        <el-row>
          <el-input
            type="textarea"
            :rows="8"
            placeholder="去重后的结果"
            v-model="resultStr"
          >
          </el-input>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { store } from "../../store";
export default {
  name: "RemoveDuplicateString",
  data: () => {
    return {
      _calc: false,
      str: "",
    };
  },

  mounted() {
    // 切换主题
    store.setTheme("light");

    // this.refresh();
  },
  computed: {
    resultStr() {
      return this.removeDuplicateString(this.str);
    },
  },
  methods: {
    onCopy(e) {
      const h = this.$createElement;
      let reg = /(.{40})/;
      let text = e.text.split(reg).filter((s) => s);
      console.log(text);
      this.$message({
        message: h(
          "div",
          null,
          text.map((s) => h("div", null, s))
        ),
        type: "success",
      });
    },
    // 去除重复字符串
    removeDuplicateString(str) {
      let charMap = {};
      str
        .split("")
        .filter((i) => i)
        .map((i) => {
          charMap[i.charCodeAt()] = i;
        });
      let newStr = "";
      let charList = [];
      for (let key in charMap) {
        charList.push({ code: parseInt(key), char: charMap[key] });
      }

      // 根据 char code 排序
      let sortedCharList = this.sort(charList, (a, b) => {
        return a.code < b.code;
      });

      // 拼接字符串
      for (let i in sortedCharList) {
        // console.log(sortedCharList[i]);
        newStr += sortedCharList[i].char;
      }

      return newStr;
    },

    /**
     * 快速排序
     * @param arr 需要排序的数组
     * @param less 小于判断方法
     * eg:
     * let less = function(a, b) {
     *   return a.score <= b.score;
     * };
     */
    sort(arr, less) {
      const quickSort = function (arr, low, high) {
        let i = low;
        let j = high;
        let pivot = arr[low];
        if (i >= j) {
          return;
        }
        while (i != j) {
          while (i < j && less(pivot, arr[j])) j--;
          if (i < j) arr[i] = arr[j];
          while (i < j && less(arr[i], pivot)) i++;
          if (i < j) arr[j] = arr[i];
        }
        arr[i] = pivot;
        quickSort(arr, low, i - 1);
        quickSort(arr, i + 1, high);
      };
      quickSort(arr, 0, arr.length - 1);
      return arr;
    },
  },
};
</script>

<style lang="less">
.content {
  max-width: 600px;
  padding: 10px;
}

.row {
  padding: 10px 0px;
}

.text {
  text-align: center;

  small {
    display: block;
    font-size: 0.8rem;
    color: #ccc;
  }
}
</style>
