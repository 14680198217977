<template>
  <div class="layout-content">
    <!-- <h1>This is an tools page</h1> -->
    <el-container>
      <el-row>
        <el-col :xs="0" :md="24">
          <el-aside>
            <el-menu
              default-active="0"
              @open="handleOpen"
              @close="handleClose"
              @select="handleSelect"
            >
              <el-menu-item
                v-for="(item, index) in toolList"
                :key="index"
                :index="'' + index"
              >
                {{ index + 1 }}
                <!-- <i class="el-icon-menu"></i> -->
                <span slot="title">{{ item.title }}</span>
              </el-menu-item>
            </el-menu>
          </el-aside>
        </el-col>
        <el-col>
          <!-- <el-row justify="flex" :gutter="10" style="flex-wrap: wrap">
            <el-col v-for="(item, index) in toolList" :key="index">
              <el-tag type="success">{{ item.title }}</el-tag></el-col
            >
          </el-row> -->
        </el-col>
      </el-row>

      <el-main>
        <TransitionFadeLeft>
          <template v-for="(item, index) in toolList">
            <div v-if="index == currentSelect" :key="index">
              <component :is="item.component"></component>
            </div>
          </template>
        </TransitionFadeLeft>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { store } from "../store";
import TransitionFadeLeft from "../components/transition/TransitionFadeLeft";
import CheckStringPairedSymbol from "../components/tools/CheckStringPairedSymbol";
import QRcodeGenerator from "../components/tools/QRcodeGenerator";
import RandomString from "../components/tools/RandomString";
import ShuffleString from "../components/tools/ShuffleString";
import RemoveDuplicateString from "../components/tools/RemoveDuplicateString";
export default {
  data() {
    return {
      currentSelect: 0,
      toolList: [
        { title: "二维码生成", component: "QRcodeGenerator" },
        { title: "随机字符串生成", component: "RandomString" },
        { title: "打乱字符串顺序", component: "ShuffleString" },
        { title: "去除字符串重复字符", component: "RemoveDuplicateString" },
        { title: "检查字符串括号配对", component: "CheckStringPairedSymbol" },
      ],
    };
  },
  mounted() {
    // 切换主题
    store.setTheme("light");
  },
  computed: {
    currentComponent() {
      return this.toolList[this.currentSelect];
    },
  },
  components: {
    TransitionFadeLeft,
    CheckStringPairedSymbol,
    QRcodeGenerator,
    RandomString,
    ShuffleString,
    RemoveDuplicateString,
  },
  methods: {
    handleOpen() {},
    handleClose() {},
    handleSelect(e) {
      this.currentSelect = e;
      console.log("select", e);
    },
  },
};
</script>

<style lang="less" scoped></style>
