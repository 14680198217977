<template>
  <div>
    <el-row type="flex" justify="space-around" class="content-wrap">
      <el-col :xs="23" :sm="20" :md="16" :lg="12">
        <el-row>
          <h1 class="text">
            随机字符串生成器
            <small>random string generator</small>
          </h1>
        </el-row>
        <el-row class="text"></el-row>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="包含字符">
            <el-checkbox-group v-model="form.checkList" :min="1">
              <el-checkbox label="nums">包含数字</el-checkbox>
              <el-checkbox label="low_letter">包含小写字母</el-checkbox>
              <el-checkbox label="up_letter">包含大写字母</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="其他字符">
            <el-input
              v-model="form.customeLetters"
              placeholder="_-@#!"
              @change="refresh"
            ></el-input>
          </el-form-item>

          <el-form-item label="长度">
            <el-row>
              <el-input-number
                v-model="form.count"
                size="small"
                controls-position="right"
                :min="1"
                @change="refresh"
              ></el-input-number>
            </el-row>
            <el-row>
              <el-button
                v-for="(count, index) in [10, 15, 20, 30, 200]"
                :key="index"
                size="mini"
                @click="setCount(count)"
                >{{ count }}</el-button
              >
            </el-row>
          </el-form-item>

          <el-form-item label>
            <el-button-group>
              <el-button @click="refresh" type="primary">重新生成</el-button>
              <el-input-number
                v-model="form.resultCount"
                @change="refresh"
                controls-position="right"
                :min="1"
                placeholder="结果个数"
              ></el-input-number>
            </el-button-group>
          </el-form-item>

          <el-form-item label="生成结果">
            <el-row
              v-for="(item, index) in form.resultCount"
              :key="index"
              style="padding-bottom: 4px;"
            >
              <el-input v-model="resultList[index]">
                <el-button
                  slot="append"
                  icon="el-icon-document-copy"
                  v-clipboard:copy="resultList[index]"
                  v-clipboard:success="onCopy"
                  >点击复制</el-button
                >
              </el-input>
            </el-row>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { store } from "../../store";
export default {
  name: "RandomString",
  data: () => {
    return {
      letterList: {
        nums: "0123456789",
        low_letter: "abcdefghijklmnopqrstuvwxyz",
        up_letter: "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
      },
      form: {
        count: 20, // 长度
        customeLetters: "", // 自定义字符
        checkList: ["nums", "low_letter", "up_letter"],
        resultCount: 1
      },
      _calc: false,
      resultList: {
        0: "000",
        1: "aaa",
        2: "222"
      }
    };
  },
  mounted() {
    // 切换主题
    store.setTheme("light");

    this.refresh();
  },
  computed: {
    letters() {
      let letters = "";
      for (let i in this.form.checkList) {
        letters += this.letterList[this.form.checkList[i]];
      }
      letters += this.form.customeLetters;
      return letters;
    }
  },
  methods: {
    onCopy(e) {
      const h = this.$createElement;
      let reg = /(.{40})/;
      let text = e.text.split(reg).filter(s => s);
      console.log(text);
      this.$message({
        message: h("div", null, text.map(s => h("div", null, s))),
        type: "success"
      });
      // this.$message({
      //   message: "复制成功：" + text,
      //   type: "success",
      // });
    },
    refresh() {
      let newResultList = {};
      for (let j = 0; j < this.form.resultCount; j++) {
        let letters = this.letters;
        let result = "";
        for (let i = 0; i < this.form.count; i++) {
          let rand = Math.floor(Math.random() * letters.length);
          result += letters[rand];
        }
        newResultList[j] = result;
      }
      // this.calc = !this.calc;
      this.resultList = newResultList;
    },
    setCount(num = 20) {
      this.form.count = num;
      this.refresh();
    }
  }
};
</script>

<style lang="less">
.content {
  max-width: 600px;
  padding: 10px;
}

.row {
  padding: 10px 0px;
}

.text {
  text-align: center;

  small {
    display: block;
    font-size: 0.8rem;
    color: #ccc;
  }
}
</style>
