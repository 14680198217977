<template>
  <div>
    <el-row type="flex" justify="space-around" class="content-wrap">
      <el-col :xs="23" :sm="20" :md="16" :lg="12">
        <el-row>
          <h1 class="text">
            字符串打乱
            <small>random string generator</small>
          </h1>
        </el-row>
        <el-row class="text"></el-row>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="生成结果">
            <el-row
              v-for="(item, index) in form.resultCount"
              :key="index"
              style="padding-bottom: 4px"
            >
              <el-input v-model="resultList[index]">
                <el-button
                  slot="append"
                  icon="el-icon-document-copy"
                  v-clipboard:copy="resultList[index]"
                  v-clipboard:success="onCopy"
                  >点击复制</el-button
                >
              </el-input>
            </el-row>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { store } from "../../store";
export default {
  name: "ShuffleString",
  data: () => {
    return {
      letterList: {
        nums: "0123456789",
        low_letter: "abcdefghijklmnopqrstuvwxyz",
        up_letter: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      },
      form: {
        count: 20, // 长度
        customeLetters: "", // 自定义字符
        checkList: ["nums", "low_letter", "up_letter"],
        resultCount: 1,
      },
      _calc: false,
      resultList: {
        0: "000",
        1: "aaa",
        2: "222",
      },
    };
  },
  mounted() {
    // 切换主题
    store.setTheme("light");

    // this.refresh();
  },
  computed: {
    letters() {
      let letters = "";
      for (let i in this.form.checkList) {
        letters += this.letterList[this.form.checkList[i]];
      }
      letters += this.form.customeLetters;
      return letters;
    },
  },
  methods: {
    onCopy(e) {
      const h = this.$createElement;
      let reg = /(.{40})/;
      let text = e.text.split(reg).filter((s) => s);
      console.log(text);
      this.$message({
        message: h(
          "div",
          null,
          text.map((s) => h("div", null, s))
        ),
        type: "success",
      });
      // this.$message({
      //   message: "复制成功：" + text,
      //   type: "success",
      // });
    },
    shuffle(arrStr, times) {
      let arr = arrStr.split("");
      for (let i = 0; i < times; i++) {
        let index = Math.floor(Math.random() * arr.length);
        let temp = arr[arr.length - 1];
        arr[arr.length - 1] = arr[index];
        arr[index] = temp;
      }
      return arr.join().replace(/,/g, "");
    },
  },
};
</script>

<style lang="less">
.content {
  max-width: 600px;
  padding: 10px;
}

.row {
  padding: 10px 0px;
}

.text {
  text-align: center;

  small {
    display: block;
    font-size: 0.8rem;
    color: #ccc;
  }
}
</style>
