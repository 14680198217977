<template>
  <div style="padding: 10px 0px; min-height: 600px;">
    <el-row>
      <h1>二维码生成工具</h1>
    </el-row>

    <el-tabs v-model="activeName">
      <el-tab-pane label="普通二维码" name="first">
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-row>
              <el-col>
                <p>要生成的内容：</p>
                <el-input v-model="text" placeholder="请输入内容"></el-input>
              </el-col>
              <el-col>
                <p>尺寸：</p>
                <el-row type="flex" justify="space-between" align="middle">
                  <el-col :span="6">
                    <el-input type="number" v-model.number="size" placeholder="尺寸"></el-input>
                  </el-col>
                  <el-col :span="18">
                    <el-tag
                      class="quick-size-tag"
                      style="margin-left: 8px; cursor: pointer;"
                      v-for="(item, index) in [64,128,144,256,512]"
                      :key="index"
                      :data-value="item"
                      @click="resize"
                    >{{item}}</el-tag>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row>
              <p style="color: #aaa; font-size: 0.7rem;">保存二维码：右键图片，另存为</p>
            </el-row>
            <qrcode :value="text" :size="size" level="H"></qrcode>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="wifi 二维码" name="second">
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-row>
              <el-col>
                <p>WIFI SSID / WIFI 名称：</p>
                <el-input v-model="wifi_name" placeholder="SSID/Wi-Fi名称"></el-input>
              </el-col>
              <el-col>
                <p>WIFI 密码：</p>
                <el-input v-model="wifi_pwd" placeholder="Wi-Fi 密码"></el-input>
              </el-col>
              <el-col>
                <p>密码类型：</p>
                <el-select v-model="wifi_type" placeholder="Wi-Fi 密码类型">
                  <el-option label="WPA/WPA2" value="WPA"></el-option>
                  <el-option label="WEP" value="WEP"></el-option>
                  <el-option label="无密码" value></el-option>
                </el-select>
              </el-col>
              <el-col>
                <p>尺寸：</p>
                <el-row type="flex" justify="space-between" align="middle">
                  <el-col :span="6">
                    <el-input type="number" v-model.number="size" placeholder="尺寸"></el-input>
                  </el-col>
                  <el-col :span="18">
                    <el-tag
                      class="quick-size-tag"
                      style="margin-left: 8px; cursor: pointer;"
                      v-for="(item, index) in [64,128,144,256,512]"
                      :key="index"
                      :data-value="item"
                      @click="resize"
                    >{{item}}</el-tag>
                  </el-col>
                </el-row>
              </el-col>
              <el-col>
                <p>wifi 字符串格式：</p>
                <p class="code">{{wifi_tip}}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row>
              <p style="color: #aaa; font-size: 0.7rem;">保存二维码：右键图片，另存为</p>
            </el-row>
            <qrcode :value="text" :size="size" level="H"></qrcode>
          </el-col>
        </el-row>
      </el-tab-pane>
      <!-- <el-tab-pane label="角色管理" name="third"></el-tab-pane>
      <el-tab-pane label="定时任务补偿" name="fourth"></el-tab-pane>-->
    </el-tabs>
  </div>
</template>
   
<script>
import qrcode from "qrcode.vue"; // 引入qrcode
export default {
  name: "QRcodeGenerator",
  data: () => {
    return {
      activeName: "first",
      text: "",
      size: 256,
      wifi_type: "WPA",
      wifi_name: "",
      wifi_pwd: "",
      wifi_tip: "WIFI:S:<SSID>;T:<WPA|WEP|>;P:<password>;;"
    };
  },
  components: { qrcode },
  watch: {
    wifi_name() {
      this.setWifiCode();
    },
    wifi_pwd() {
      this.setWifiCode();
    },
    wifi_type() {
      this.setWifiCode();
    }
    // text() {
    //   this.qrcode(this.text);
    // }
    // w() {
    //   this.resize();
    // },
    // h() {
    //   this.resize();
    // },
    // size() {
    //   this.resize();
    // }
  },
  mounted() {},
  methods: {
    setWifiCode() {
      let ssid = this.wifi_name;
      let type = this.wifi_type;
      let pwd = this.wifi_pwd;
      this.text = `WIFI:S:${ssid};T:${type};P:${pwd};;`;
    },
    resize(e) {
      let size = e.target.dataset.value;
      this.size = parseInt(size);
    }
  }
};
</script>

<style lang="less" scoped>
.quick-size-tag {
  transition: all 0.2s;
  &:hover {
    color: darken(rgb(0, 213, 160), 15%);
    border-color: rgb(0, 213, 160);
  }
}
.code {
  background-color: rgb(255, 239, 239);
  color: rgb(193, 80, 80);
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
}
</style>
