/**
 * 检查字符串中的成对符号，是否成对（检查不成对的错误行数）
 */
<template>
  <div>
    <h2>检查字符串每行括号是否匹配</h2>
    <p>包括：大括号；中括号；小括号</p>
    <el-input type="textarea" :rows="10" placeholder="请填写需要校验的字符串" v-model="str"></el-input>
    <div class="result" v-if="rows && rows.length >= 1 && rows[0] != ''">
      <el-row type="flex" v-for="(str, index) in rows" :key="index">
        <template v-if="check(str)">
          <el-col :span="2" style="color: red;">{{index}}</el-col>
          <el-col :span="22" style="color: red;">{{str}}</el-col>
        </template>
        <template v-else>
          <el-col :span="2">{{index}}</el-col>
          <el-col :span="22">{{str}}</el-col>
        </template>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => {
    return { str: "" };
  },
  computed: {
    rows: function() {
      return this.str.split("\n");
    }
  },
  methods: {
    // 检查 inStr 中的 char 个数
    getSymbolCount(char, inStr) {
      return inStr.split("").filter(i => i == char).length;
    },
    check(str) {
      let a1 = this.getSymbolCount("[", str);
      let a2 = this.getSymbolCount("]", str);
      let b1 = this.getSymbolCount("{", str);
      let b2 = this.getSymbolCount("}", str);
      let c1 = this.getSymbolCount("(", str);
      let c2 = this.getSymbolCount(")", str);
      if (a1 != a2 || b1 != b2 || c1 != c2) {
        // 有不匹配的符号
        return true;
      }
      return false;
    }
  }
};
</script>

<style scoped>
.result {
  overflow: scroll;
  padding: 10px;
  margin: 20px 0px;
  color: #555;
  border: 1px solid #3ba;
  border-radius: 4px;
}
</style>
